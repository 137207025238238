var client;
var google;
let scriptEle = document.createElement("script");
let mapKey = '';

let route = `https://maps.googleapis.com/maps/api/js?key=${getKey()}&libraries=places&language=en&callback=getKey`;
scriptEle.setAttribute("src", route);

let stripeRouteEle = document.createElement("script");
route = 'https://js.stripe.com/v3/';
stripeRouteEle.setAttribute("src", route);

function getKey() {
    if (window.location.href.includes('.financemutual.com')) {
        mapKey = 'AIzaSyAC-sYjuX1Avr6W_kNtfL06HQtNnWluAvs';
    } else {
        mapKey = 'AIzaSyDfsOQdPjaofT7MKQFzlazJn1rnZANi91w'
    }
    return mapKey;
}
document.head.appendChild(scriptEle)
document.head.appendChild(stripeRouteEle);